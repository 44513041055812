<script >
import {mapGetters} from "vuex";
import  voucherbg1 from '@/assets/voucher/voucher-bg-1.svg'
import  voucherbg2 from '@/assets/voucher/voucher-bg-2.svg'
import  voucherbg3 from '@/assets/voucher/voucher-bg-3.svg'
import  voucherbg4 from '@/assets/voucher/voucher-bg-4.svg'
import  voucherbg5 from '@/assets/voucher/voucher-bg-5.svg'
import  voucherbg6 from '@/assets/voucher/voucher-bg-6.svg'
import  voucherbg7 from '@/assets/voucher/voucher-bg-7.svg'
import  voucherbg8 from '@/assets/voucher/voucher-bg-8.svg'
import  voucherbg9 from '@/assets/voucher/voucher-bg-9.svg'

export default {
  name: "VoucherCard",
  props: {

  },
  data(){
    return {
      showFront: true,
      transitioning : false,
    }
  },
  computed: {
    ...mapGetters("companyProfile",["companyData"]),
    generateRandomNumber() {
      let number = Math.floor(Math.random() * 9) + 1;
      if (number === 1) {
        return voucherbg1;
      }
      else if (number === 2) {
        return voucherbg2;
      }
      else if (number === 3) {
        return voucherbg3;
      }
      else if (number === 4) {
        return voucherbg4;
      }
      else if (number === 5) {
        return voucherbg5;
      }
      else if (number === 6) {
        return voucherbg6;
      }
      else if (number === 7) {
        return voucherbg7;
      }
      else if (number === 8) {
        return voucherbg8;
      }
      else if (number === 9) {
        return voucherbg9;
      }
      else {
        return voucherbg2;
      }
    }
  },
  methods: {
    flipCard() {
         this.showFront = !this.showFront;
    },

  },
  created() {

  }
}
</script>

<template>
  <div class="parent-card" :style="{width: showFront ? '400px' : '500px', height: showFront ? '150px' : '200px'}">
  <transition name="flip">
  <v-card class="tw-flex tw-flex-col front card tw-items-center tw-justify-center"
       key="front" @click="flipCard"  v-if="showFront" style="z-index: 0"
          :style="{width: showFront ? '400px' : '500px',
          height: showFront ? '150px' : '200px', backgroundImage: 'url(' + generateRandomNumber + ')'}">
   <h6 class="voucher-title tw-mt-4">Detty December</h6>
    <div class="tw-flex tw-flex-row tw-place-items-center">
      <h6 class="currency">₦</h6>
      <h6 class="amount">10,000</h6>
    </div>
    <div class="code">#########htY7</div>
    <div class="tw-flex tw-flex-row tw-justify-between tw-w-full tw-mb-2">
      <h6 class="validity">Valid till : October 2025</h6>
      <h6 class="validity">Status: assigned</h6>
    </div>
  </v-card>
  <v-card class="tw-flex tw-flex-col back tw-w-full card"
       key="back" @click="flipCard" style="z-index: 1" v-else
          :style="{width: showFront ? '400px' : '500px', height: showFront ? '150px' : '200px', backgroundImage: 'url(' + generateRandomNumber + ')'}">
    <div class="tw-flex tw-w-full tw-flex-col ">
      <label class="customer-email">Customer email</label>
      <v-text-field type="email" :color="companyData.companyColor"
                    prepend-inner-icon="mdi-email" placeholder="customer@example.com"
                    solo hide-details></v-text-field>
      <v-btn class="send-btn tw-mt-2" :style="{backgroundColor: companyData.companyColor}">Send</v-btn>
    </div>
    <div class="tw-hidden tw-w-full tw-flex-col ">
      <div class="tw-flex tw-w-full tw-flex-row">
        <h6 class="text-header">Issued To: </h6><h6 class="text-desc">paul@gmail.com</h6>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-my-3">
        <h6 class="text-header">Valid till: </h6><h6 class="text-desc">March 2025</h6>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row">
        <h6 class="text-header">Booking: </h6><h6 class="text-desc">GfT5tJKuA</h6>
      </div>
    </div>
  </v-card>
  </transition>
  </div>
</template>

<style scoped lang="scss">
.parent-card{
  position: relative;
  margin: 10px 10px;
  @media screen and(max-width: 800px){
    margin: 10px 0;
  }
}
.card{
  position: absolute;
  //background-image: url("../../assets/voucher/voucher-bg-4.svg");
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  padding: 1.8rem;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    width: 100%;
    height: 150px;
  }
  animation: ease-in-out;
  animation-duration: 1s;
  animation-direction: normal;
}
.front{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-family: "Bebas Neue", serif;
  font-size: 1rem;
  font-weight: bolder;
  color: var(--brown);
}

.voucher-title{
  font-family: "Bebas Neue", serif;
  font-size: 1rem;
  font-weight: bolder;
  color: var(--black);
}

.currency{
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--brown);
}
.amount{
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--brown);
}
.validity{
  font-family: "Doto", serif;;
  font-size: 12px;
  font-weight: bold;
  font-variation-settings:
      "ROND" 0;
  color: var(--brown);
}
.customer-email{
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
  color: var(--brown);
}
.send-btn{
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
  text-transform: capitalize;
  color: var(--white);
  //background-color: var(--black) !important;

}

.text-header{
  width: 50%;
  font-family: "Doto", serif;;
  font-size: 16px;
  font-weight: bold;
  font-variation-settings:
      "ROND" 0;
  color: var(--black);
}
.text-desc{
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: var(--black);
}

.flip-enter-active,
.flip-leave-active {
  transition: transform 2s;
  backface-visibility: hidden !important;
}

.flip-enter-from {
  transform: rotateY(360deg);
}

.flip-leave-to {
  transform: rotateY(360deg);
}

.flip-back-enter-active,
.flip-back-leave-active {
  transition: transform 2s;
  backface-visibility: hidden !important;
}

.flip-back-enter-from {
  transform: rotateY(-360deg);
}

.flip-back-leave-to {
  transform: rotateY(-360deg);
}
.unassigned{
  z-index: 0;
}
.assigned{
  z-index: 1;
}
.code{
  width: max-content;
  height: max-content;
  background-color: var(--brown);
  color: var(--black);
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  padding: 0 1rem;
}
</style>