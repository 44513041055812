<script>
import {mapGetters} from "vuex";
import VoucherCard from "@/components/reuseables/VoucherCard.vue";
import Modal from "@/components/reuseables/Modal.vue";

export default {
  name: "CouponDashboard",
  components: {Modal, VoucherCard},
  props: {},
  data(){
    return {
      tab: null,
      discounts: [],
      vouchers: [],
      headers: [
        { text: "", value: "sno" },
        { text: "", value: "name" },
        { text: "", value: "type" },
        { text: "", value: "unit" },
        { text: "", value: "unitAmount" },
        {text: "", value: "startDate"},
        { text: "", value: "endDate" },
        { text: "", value: "status" },
        { text: "", value: "actions", align: "end" },
      ],
      search: null,
      discount_not_found: "Discounts will display here",
      selectedRow: [],
      singleSelect: false,
      voucherModal: true,
      voucherDetail: {},
    }
  },
  computed: {
    ...mapGetters("companyProfile", ['companyData'])
  },
  methods:{
    viewSingle(){},
    showVoucherModal(){
      this.voucherModal = !this.voucherModal;
    }
  }
}
</script>

<template>
<div class="tw-flex tw-w-full tw-flex-col">
  <v-tabs
      fixed-tabs
      align-with-title
      v-model="tab"
      class="tw-pb-5 lg:tw-pb-0"
      :slider-color="companyData.companyColor"
      :color="companyData.companyColor">
    <v-tab class="tw-justify-start switch-header tw-p-1">
      <v-icon class="tw-mr-2">mdi-percent</v-icon>
      Discounts
    </v-tab>
    <v-tab class="tw-justify-start switch-header tw-p-1">
      <v-icon class="tw-mr-2">mdi-ticket-percent</v-icon>
      Vouchers
    </v-tab>
    <v-tab-item class="tw-flex tw-w-full tw-flex-col
              tw-px-5 lg:tw-px-28 lg:tw-pt-20
              tw-pt-10 tw-bg-white tw-pb-5 tw-rounded-md">

    <div class="tw-flex tw-w-full tw-justify-end tw-place-items-center">
      <div class="tw-w-full lg:tw-w-4/12">
      <v-text-field
          placeholder="Search"
          class="p-0"
          solo

          hide-details
          color="#FDFFFC"
          v-model="search">
        <template #prepend-inner>
          <v-icon :color="companyData.companyColor" class="ml-5">
            mdi-magnify
          </v-icon>
        </template>
        <template #append>
          <v-icon :color="companyData.companyColor"> mdi-filter-variant </v-icon>
        </template>
      </v-text-field>
      </div>
    </div>
      <v-data-table
          :headers="headers"
          v-model="selectedRow"
          :mobile-breakpoint="0"
          :search="search"
          item-key="id"
          :single-select="singleSelect"
          height="available"
          :hide-default-footer="discounts.length <= 10"
          :items="discounts"
          :no-data-text="discount_not_found"
          class="mt-4 mx-1 table"
          :checkbox-color="companyData.companyColor"
          @click:row="viewSingle">

        <template #header.sno>
          <span class="status"> S/N </span>
        </template>
        <template v-slot:[`item.sno`]="{ item }">
          <span class="td-style">{{ item.sno }}</span>
        </template>
        <template #header.name>
          <span class="status"> Name </span>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span class="td-style">{{ item.name }}</span>
        </template>
        <template #header.type>
          <span class="status"> Type </span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span class="td-style">{{ item.type }}</span>
        </template>
        <template #header.unit>
          <span class="status"> Unit </span>
        </template>
        <template v-slot:[`item.unit`]="{ item }">
          <span class="td-style">{{ item.unit }}</span>
        </template>
        <template #header.unitAmount>
          <span class="status"> Unit Amount </span>
        </template>
        <template v-slot:[`item.unitAmount`]="{ item }">
          <span class="td-style">{{ item.unitAmount }}</span>
        </template>
        <template #header.startDate>
          <span class="status"> Start Date </span>
        </template>
        <template v-slot:[`item.startDate`]="{ item }">
          <span class="td-style">{{ item.startDate }}</span>
        </template>
        <template #header.endDate>
          <span class="status"> End Date </span>
        </template>
        <template v-slot:[`item.endDate`]="{ item }">
          <span class="td-style">{{ item.endDate }}</span>
        </template>
        <template #header.status>
          <span class="status"> Status </span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span class="td-style">{{ item.status }}</span>
        </template>
        <template #header.actions>
          <span class="status"></span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <td class="d-flex justify-center">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon :color="companyData.companyColor">mdi-dots-horizontal</v-icon>
                </v-btn>
              </td>
            </template>
            <v-list>
              <v-list-item>
                {{item}}
              </v-list-item>
            </v-list>
          </v-menu>
          </template>
      </v-data-table>
    </v-tab-item>
    <v-tab-item class="tw-flex tw-w-full  lg:tw-pt-20 tw-pt-10 tw-bg-white tw-pb-5  voucher-container tw-px-10">
      <div class="tw-flex tw-w-full tw-flex-row tw-justify-end tw-items-center">
        <v-btn class="create-btn" :style="{backgroundColor: companyData.companyColor}">Create Voucher <v-icon>mdi-plus</v-icon></v-btn>
        <div class="tw-flex tw-w-4/12 tw-ml-5">
        <v-text-field
            placeholder="Search"
            class="p-0" solo
            hide-details
            color="#FDFFFC"
            v-model="search">
          <template #prepend-inner>
            <v-icon :color="companyData.companyColor" class="ml-5">
              mdi-magnify
            </v-icon>
          </template>
          <template #append>
            <v-icon :color="companyData.companyColor"> mdi-filter-variant </v-icon>
          </template>
        </v-text-field>
        </div>
      </div>
     <voucher-card />
      <voucher-card />
      <voucher-card />
      <voucher-card />
      <voucher-card />
      <voucher-card />
      <voucher-card />
    </v-tab-item>
  </v-tabs>
  <modal section="create-voucher"
         :dialog="voucherModal"
         @close="showVoucherModal"
         :voucher-detail="voucherDetail"
        :color="companyData.companyColor">

  </modal>
</div>
</template>

<style scoped lang="scss">
.switch-header {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: none;
}
.table-container {
  width: 100%;
  .table-container-header {
    font-family: "Inter", serif !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    color: #004aad;
  }
}

.voucher-container {
  width: 100%;
  min-height: 50vh;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.create-btn {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
  text-transform: capitalize;
}
::v-deep
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
> .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
.v-slide-group__prev {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.v-tabs--fixed-tabs > .v-tabs-bar .v-tab {
  @media screen and (max-width: 768px) {
    width: max-content !important;
    margin-right: 1rem !important;
  }
}

::v-deep .v-slide-group__content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.v-application--is-ltr
.v-tabs--align-with-title
> .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
> .v-slide-group__wrapper
> .v-tabs-bar__content
> .v-tab:first-child,
.v-application--is-ltr
.v-tabs--align-with-title
> .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
> .v-slide-group__wrapper
> .v-tabs-bar__content
> .v-tabs-slider-wrapper
+ .v-tab {
  margin-left: 0 !important;
}

.setting {
  min-height: 85vh;
}

::v-deep .theme--light.v-tabs-items {
  background: none !important;
}

::v-deep .theme--light.v-tabs > .v-tabs-bar {
  background: none !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 0 0 5px;
}
</style>